<script setup lang="ts">
import type { Amount, OrderResult } from '@/env'
import {
  computed,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue'
import { useToast } from 'vue-toastification'
import { MONTHS, MINS } from '@/constants'
import * as fennec from '@/fennec'
import { useStore, useSearchStore } from '@/stores'
import { diffDays, getOptionFundsPlaces } from '@/utils'

import SuccessToast from '@/components/toasts/SuccessToast.vue'
import SuccessIcon from '@/components/toasts/SuccessIcon.vue'

import SmallQuarter from './SmallQuarter.vue'
import SmallLoading from './SmallLoading.vue'
import Dropdown from './Dropdown.vue'
import ScanToPayModal from './modals/ScanToPay.vue'
import OrderDetailsAskInfo from './OrderDetailsAskInfo.vue'
import OrderDetailsBidInfo from './OrderDetailsBidInfo.vue'
import ErrorIcon from '@/components/toasts/ErrorIcon.vue'
import ErrorToast from '@/components/toasts/ErrorToast.vue'
import CloseIcon from '@/components/toasts/CloseIcon.vue'
import { POSITION } from 'vue-toastification'
import { createOrderMVM } from '@/connection/mvm'

interface Props {
  type: string
  label: string
  price: number
  prices: Amount[]
  formatPrice: (n: number) => string
  getPrice: (n: number) => number
  date: number
  dates: Amount[]
  formatDate: (n: any) => string
  getDate: (n: any) => string | number
  max?: number
  index: number
  amount: string
}

const toast = useToast()
const store = useStore()
const search = useSearchStore()

const props = withDefaults(defineProps<Props>(), {
  max: 99999,
})

const emit = defineEmits([
  'close',
  'next',
  'prev',
  'update:price',
  'update:date',
  'update:amount',
])

const updatePrice = (val: number) => {
  emit('update:price', val)
}
const updateDate = (val: number) => {
  emit('update:date', val)
}

const formatTip = (a: number, b: number) => `≈ ${props.formatPrice(a * b)}`

const input = ref()
const quantityPurchased = ref()
// const price = ref(props.price)
// const date = ref(props.date)
const loading = ref(false)
const showPrices = ref(false)
const showDays = ref(false)
const tip = ref(formatTip(Number(props.amount), props.price))
const qrcodeShow = ref(false)
const qrcode = ref({
  code_id: '',
  trace_id: '',
})
const abort = ref<AbortController | null>(null)
const timeOutId = ref()
const orderId = ref('')
const min = ref(MINS[search.baseCurrency] || 0.1)

const openDropdown = (e: any) => {
  const { type } = e.target.dataset
  if (type === 'prices') {
    if (showDays.value) {
      showDays.value = false
    }
    showPrices.value = !showPrices.value
  } else {
    if (showPrices.value) {
      showPrices.value = false
    }
    showDays.value = !showDays.value
  }
}

const focusInput = () => {
  quantityPurchased.value.classList.add('focsing')
}
const blurInput = () => {
  quantityPurchased.value.classList.remove('focsing')
}
// ≈ 5,000 USDC
// Min.Buy 0.1 BTC
// Max.Buy 10 BTC
const updateTip = (value: number) => {
  if (value < min.value) {
    tip.value = `Min.${props.label} ${min.value} ${search.baseCurrency}`
    quantityPurchased.value.classList.add('warning')
  } else if (value > props.max) {
    tip.value = `Max.${props.label} ${props.max} ${search.baseCurrency}`
    quantityPurchased.value.classList.add('warning')
  } else {
    tip.value = formatTip(value, props.price)
    quantityPurchased.value.classList.remove('warning')
  }
}
const changeInput = (e: any) => {
  const amount = e.target.value.trim()
  emit('update:amount', amount)
  updateTip(Number(amount))
}
onMounted(() => {
  input.value.addEventListener('focus', focusInput, false)
  input.value.addEventListener('blur', blurInput, false)
})
onBeforeUnmount(() => {
  input.value.removeEventListener('focus', focusInput)
  input.value.removeEventListener('blur', focusInput)
})

const priceCard = computed(() => {
  const item = props.dates.find(
    (d) => d.value.expiration_timestamp === props.date / 1000
  )
  if (!item) return null
  return item.value
})

// 当前 BIDS/ASKS 第一条记录
const currentPrice = computed(() => {
  const k = search.side === 'BID' ? 'bid' : 'ask'
  let e
  if (priceCard.value) {
    e = priceCard.value[`${k}s`][props.index]
  }

  if (!e) {
    e = {
      amount: '',
      funds: '',
      price: '',
      side: k.toLocaleUpperCase(),
    }
  }

  return e
})

// interest 50 USDT （ 权利金 x 购买数量 ）
const interestValue = computed(() => {
  let s = ''
  if (priceCard.value) {
    const p = currentPrice.value.price
    const n = getOptionFundsPlaces(priceCard.value.option_type)
    const a = Number(props.amount || 0)
    if (a === 0) {
      return '0'
    }
    s = (Number(props.amount || 0) * Number(p)).toFixed(n)
  }
  return s
})

// Annualized Yield 30%（计算公式：年化收益率=[（权利金收益 / 本金）/ 投资天数] *365 ×100%，投资天数小于1天按一天算，投资天数大于1天小于2天，按1天计算）
// https://github.com/24h-purewater/option-dance-web/blob/main/src/views/home/Home.vue#L808
const yieldValue = computed(() => {
  let s = 0
  if (priceCard.value) {
    const { strike_price, expiration_date, option_type } = priceCard.value
    const p = currentPrice.value.price
    const a = Number(props.amount || 0)
    if (a === 0) {
      return '0'
    }
    let diff = Math.abs(diffDays(expiration_date, new Date()))
    if (diff < 2) {
      diff = 1
    }
    s = (Number(p) * 36500) / diff
    if (option_type === 'PUT') {
      s /= strike_price
    }
  }

  return s ? s.toFixed(2) : String(0)
})

// 21 January 2022
const expirationValue = computed(() => {
  let s = ''
  if (priceCard.value) {
    const { expiration_date } = priceCard.value
    const date = new Date(expiration_date)
    s = `${date.getDate()} ${MONTHS[date.getMonth()]} ${date.getFullYear()}`
  }

  return s
})

// 下单
const buy = async () => {
  if (!store.logged) {
    const d = document.getElementById('connect-wallet')
    if (d) {
      d.click()
    }
    return
  }

  const amount = Number(props.amount)
  if (!amount || amount < min.value || amount > props.max) {
    return
  }

  loading.value = true

  let done = false

  try {
    // 创建订单时：优买是卖出ASK，大跌保是买入BID，对应稳盈是ASK，大涨保是BID
    const side = search.side === 'BID' ? 'ASK' : 'BID'
    const type = 'L'
    const instrument_name = priceCard.value.instrument_name
    const p = String(currentPrice.value.price)
    const a = String(amount)
    const data = {
      side,
      type,
      price: p,
      amount: a,
      instrument_name,
    }
    const { code_id, trace_id } = (await store.post('order-request', {
      body: JSON.stringify(data),
    })) as OrderResult
    // 关闭前一次订单追踪
    closeTraceOrder()
    abort.value = new AbortController()
    next(trace_id)
    if (store.type === 'fennec') {
      done = await fennec.createOrder(code_id)
    } else if (store.type === 'mixin') {
      qrcode.value = {
        code_id,
        trace_id,
      }
      qrcodeShow.value = true
    } else if (store.type === 'mvm') {
      toast.error(
        {
          type: 'error',
          component: ErrorToast,
          props: {
            text: 'This feature is under development',
          },
        },
        {
          // timeout: 10000,
          timeout: false,
          icon: ErrorIcon,
          closeButton: CloseIcon,
          position: POSITION.TOP_CENTER,
          hideProgressBar: true,
        }
      )
      return
      // try {
      //   await createOrderMVM({
      //     code: code_id,
      //     multisig: true,
      //   })
      // } catch (e: any) {
      //   let message = e.message ? e.message : ''
      //   if (message.indexOf('user rejected transaction') > -1) {
      //     message = 'user rejected transaction'
      //   }
      //   toast.error(
      //     {
      //       type: 'error',
      //       component: ErrorToast,
      //       props: {
      //         text: message,
      //       },
      //     },
      //     {
      //       // timeout: 10000,
      //       timeout: false,
      //       icon: ErrorIcon,
      //       closeButton: CloseIcon,
      //       position: POSITION.TOP_CENTER,
      //       hideProgressBar: true,
      //     }
      //   )
      // }
    }
  } catch (err: any) {
    if (store.type === 'fennec') {
      if (err.message === 'Reject') {
        closeTraceOrder()
      }
    }
    done = true
  }

  if (done) {
    loading.value = false
  }
}

// https://test-api.option.dance/api/v1/order-trace/${trace_id}
const next = (trace_id: string) => {
  if (!abort.value || abort.value.signal.aborted) {
    return
  }

  store
    .get(`order-trace/${trace_id}`, {
      signal: abort.value.signal,
    })
    .then(
      (res: any) => {
        if (res && res.order_id === trace_id) {
          orderId.value = res.order_id
        } else {
          timeOutId.value = setTimeout(() => next(trace_id), 2000)
        }
      },
      (err) => {
        timeOutId.value = setTimeout(() => next(trace_id), 2000)
      }
    )
}

// 关闭二维码
const closeScanToPayModal = () => {
  qrcodeShow.value = false
}

// 关闭订单追踪
const closeTraceOrder = () => {
  // 定时任务
  if (timeOutId.value) {
    clearTimeout(timeOutId.value)
    timeOutId.value = 0
  }
  // 网络请求
  if (abort.value) {
    abort.value.abort()
    abort.value = null
  }
}

watch(orderId, (id, b) => {
  if (id && id !== b) {
    closeTraceOrder()
    closeScanToPayModal()
    loading.value = false

    // 通知
    toast(
      {
        type: 'success',
        component: SuccessToast,
        props: {
          text: 'Your order has been placed',
          id,
        },
      },
      {
        timeout: 10000,
        icon: SuccessIcon,
        closeButton: CloseIcon,
        hideProgressBar: true,
      }
    )
  }
})

watch(
  () => props.price,
  (a, _b) => {
    if (!quantityPurchased.value.classList.contains('warning')) {
      tip.value = formatTip(Number(props.amount || 0), a)
    }
  }
)

watch(
  () => search.baseCurrency,
  (a) => {
    min.value = MINS[a]
    if (quantityPurchased.value.classList.contains('warning')) {
      updateTip(Number(props.amount || 0))
    }
  }
)

const valueClassName = computed(() => {
  switch (props.type) {
    case 'dh':
      return 'text-quarter-dh'
    case 'sp':
      return 'text-quarter-sp'
    case 'cp':
      return 'text-quarter-cp'
    case 'bp':
      return 'text-quarter-bp'
  }
})

onUnmounted(() => {
  closeTraceOrder()
})
</script>

<template>
  <div id="order-details" class="w-107 absolute select-none">
    <div
      class="w-full h-full px-7 pt-10 pb-9 bg-neutral-1001 rounded-2.5xl inset-border"
    >
      <h3 class="font-800 text-white text-2xl mb-8 mx-2 flex items-center">
        <SmallQuarter :type="type" :size="46" class="mr-5.5" />
        Order details
      </h3>
      <SvgIcon
        name="close"
        class="absolute top-10 right-7 cursor-pointer text-neutral-455 btn-close"
        un-hover="text-white"
        width="18"
        height="18"
        @click="$emit('close')"
      />

      <div class="flex flex-row justify-between">
        <div class="w-45 h-19 bg-neutral-610 bg-opacity-10 rounded-3 relative">
          <div
            @click.self="openDropdown"
            data-type="prices"
            class="w-full h-full py-3.75 px-5 cursor-pointer dropdown-target"
          >
            <h5
              class="flex flex-row items-center text-xs font-400 text-white text-opacity-50 pointer-events-none"
            >
              {{ label }} When
              <SvgIcon name="right-arrow" width="8" height="9" class="ml-1.5" />
            </h5>
            <div
              class="text-white font-700 text-lg leading-6 tracking-0.02em mt-2 pointer-events-none"
            >
              {{ formatPrice(price) }}
            </div>
          </div>

          <Transition name="fade">
            <Dropdown
              class="top-21"
              v-if="showPrices"
              :value="price"
              @update:value="updatePrice"
              :list="prices"
              :format="formatPrice"
              :get="getPrice"
              @close="showPrices = false"
            />
          </Transition>
        </div>
        <div class="w-45 h-19 bg-neutral-610 bg-opacity-10 rounded-3 relative">
          <div
            @click.self="openDropdown"
            data-type="days"
            class="w-full h-full py-3.75 px-5 cursor-pointer dropdown-target relative"
          >
            <h5
              class="flex flex-row items-center text-xs font-400 text-white text-opacity-50 pointer-events-none"
            >
              Wait
              <SvgIcon name="right-arrow" width="8" height="9" class="ml-1.5" />
            </h5>
            <div
              class="text-white font-700 text-lg leading-6 tracking-0.02em mt-2 pointer-events-none"
            >
              {{ formatDate({ expiration_date: date }) }}
            </div>
          </div>

          <Transition name="fade">
            <Dropdown
              class="top-21"
              v-if="showDays"
              :value="date"
              @update:value="updateDate"
              :list="dates"
              :format="formatDate"
              :get="getDate"
              @close="showDays = false"
            />
          </Transition>
        </div>
      </div>
      <!-- warning -->
      <!-- focsing -->
      <div
        ref="quantityPurchased"
        class="w-full h-29 my-5 rounded-3 relative z-0 quantity-purchased"
      >
        <div
          class="w-full h-full py-4 px-5 rounded-3 bg-neutral-610 bg-opacity-10 quantity-purchased-inner"
        >
          <label
            class="flex flex-row items-center text-xs font-400 text-white text-opacity-50"
          >
            Amount
          </label>
          <div
            class="flex items-center justify-between font-700 text-2.495xl mt-1.25"
          >
            <!-- type="number" -->
            <div class="flex-grow">
              <input
                ref="input"
                :value="amount"
                @focus="focusInput"
                @blur="blurInput"
                @change="changeInput"
                @input="changeInput"
                class="text-white bg-transparent leading-10 w-full inline-block tracking-0.02em placeholder-neutral-500 font-700"
                un-focus="outline-none"
                autocomplete="off"
                :placeholder="String(min).replace(/1/g, '0')"
                type="number"
                inputmode="decimal"
                :step="min"
                :min="min"
                :max="max"
              />
            </div>
            <label
              class="flex-none text-neutral-500 leading-8.5 inline-block pl-5"
            >
              {{ search.baseCurrency }}
            </label>
          </div>
          <p class="text-xs leading-3.75 font-600 text-neutral-500 mt-2 tip">
            {{ tip }}
          </p>
        </div>
      </div>

      <OrderDetailsAskInfo
        v-if="type === 'dh' || type === 'sp'"
        :format-price="formatPrice"
        :amount="amount"
        :price="price"
        :base-currency="search.baseCurrency"
        :quote-currency="search.quoteCurrency"
        :expiration-value="expirationValue"
        :yield-value="yieldValue"
        :interest-value="interestValue"
        :value-class-name="valueClassName"
      />
      <OrderDetailsBidInfo
        v-else
        :type="type"
        :format-price="formatPrice"
        :amount="amount"
        :price="price"
        :base-currency="search.baseCurrency"
        :quote-currency="search.quoteCurrency"
        :expiration-value="expirationValue"
        :yield-value="yieldValue"
        :interest-value="interestValue"
        :value-class-name="valueClassName"
      />

      <button
        class="w-full h-16 capitalize tracking-0.01em bg-white rounded-3 font-800 text-lg text-neutral-1000 relative flex items-center justify-center"
        style="box-shadow: 0px 4px 36px #17171c"
        un-hover="btn-hover"
        :disabled="loading"
        @click="buy"
      >
        Submit Order
        <SmallLoading v-if="loading" class="w-3.5 h-3.5 ml-2.5" />
      </button>
    </div>

    <Teleport to="body">
      <ScanToPayModal
        :show="qrcodeShow"
        :data="qrcode"
        @close="closeScanToPayModal"
      />
    </Teleport>
  </div>
</template>

<style>
.quantity-purchased::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: inherit;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15) 59.24%,
    rgba(255, 255, 255, 0.06) 100%
  );
  transition: background 0.2s ease;
}
.quantity-purchased::after {
  content: '';
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  z-index: -1;
  border-radius: inherit;
  background: rgba(20, 20, 20, 1);
}
.quantity-purchased-inner {
  transition: background-color 0.2s ease;
}

.quantity-purchased.focsing .quantity-purchased-inner {
  --un-bg-opacity: 0.2;
}

.quantity-purchased.warning::before {
  background: rgba(255, 111, 97, 1);
}

.quantity-purchased .tip {
  transition: color 0.2s ease;
}
.quantity-purchased.warning .tip {
  color: rgba(255, 111, 97, 1);
}

.immediate-returns .tip {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease;
}
.immediate-returns:hover .tip {
  visibility: visible;
  opacity: 1;
}
</style>
