import { app } from '../main'
import { signAuthenticationToken } from '@foxone/mixin-api/encrypt'




export function unix(): number {
    return Math.floor(new Date().getTime() / 1000);
}

export async function connectMvm(
    callback: (type: string, code: string, step: number, account: any) => void
) {
    let mvm = app.config.globalProperties.$mvm
    await mvm.connenct("metamask");
    let mvmUserkey = mvm.user.key
    let authToken = signAuthenticationToken(mvmUserkey.client_id, mvmUserkey.session_id, mvmUserkey.private_key, "GET", "/me", "", "FULL", unix() + 60 * 60 * 24 * 365)
    callback('mvm', authToken, 1, { full_name: mvm.account, user_id: mvm.account })
}

export interface PaymentPayload {
    // transfer params
    assetId?: string;
    amount?: string;
    recipient?: string;
    traceId?: string;
    memo?: string;
    // multisig params
    code?: string;
    multisig?: boolean;
}

export interface MultisigResp {
    type: string;
    trace_id: string;
    asset_id: string;
    amount: string;
    threshold: number;
    receivers: string[];
    memo: string;
    created_at: Date;
    status: string;
    code_id: string;
}

export async function createOrderMVM(payload: PaymentPayload) {
    const code = payload.code ?? "";
    const multisig = payload.multisig ?? false;

    const asset_id = payload?.assetId ?? "";
    const opponent_id = payload?.recipient ?? "";
    const amount = payload?.amount ?? "";
    const trace_id = payload?.traceId ?? "";
    const memo = payload?.memo ?? "";
    let mvm = app.config.globalProperties.$mvm

    if (multisig) {
        const resp: Response = await fetch(`https://api.mixin.one/codes/${payload.code}`)
        let respJson = await resp.json()
        let multisigResp: MultisigResp = respJson.data
        const receivers = multisigResp?.receivers;
        const threshold = multisigResp?.threshold;
        const memo = multisigResp?.memo;
        const amount = multisigResp?.amount;
        const asset_id = multisigResp?.asset_id;
        let withdrawArgs = {
            action: { extra: memo, receivers, threshold },
            amount,
            asset_id
        }
        console.log(withdrawArgs);
        await mvm.connenct("metamask");
        console.log('mvm connected:', mvm.connected);
        console.log('mvm detail:', mvm);

        await mvm.withdraw(withdrawArgs);
        console.log('withdraw has finished');
    } else {
        await mvm.withdraw({
            action: { extra: memo, receivers: [opponent_id], threshold: 1 },
            amount,
            asset_id
        });
    }
}