<script setup lang="ts">
import { computed } from 'vue'
import {
  ACTIONS,
  BUY_OR_SELL,
  HISTORY_ORDER_STATUS,
  HISTORY_POSITION_STATUS,
} from '@/constants'
import { formatDate, USD } from '@/utils'

import SmallQuarter from './SmallQuarter.vue'

const props = withDefaults(
  defineProps<{
    type: string
    isOrder: boolean
    showStatus?: boolean
    item: Record<string, any>
    grayscale?: boolean
  }>(),
  {
    showStatus: false,
    grayscale: false,
  }
)

const { title, p0 } = ACTIONS[props.type]

const totalLabel = computed(() => {
  if (props.isOrder) {
    return 'Completed / Total'
  }
  return 'Total'
})

// 根据订单类型自行判断
const total = computed(() => {
  if (props.isOrder) {
    let s = ''
    const { remaining_amount, filled_amount } = props.item
    s += filled_amount
    s += ' / '
    s += Number((Number(remaining_amount) + Number(filled_amount)).toFixed(5))
    return s
  }
  return Math.abs(props.item.size)
})

// 根据订单类型自行判断
const funds = computed(() => {
  if (props.isOrder) {
    const { remaining_funds, filled_funds } = props.item
    return Number((Number(remaining_funds) + Number(filled_funds)).toFixed(5))
  }
  return props.item.funds
})
</script>

<template>
  <div
    class="w-79.25 h-76.5 bg--neutral-1000 px-7 pt-8 pb-8.5 rounded-5 border border-white border-opacity-20 box-border cursor-pointer"
    un-hover="bg-neutral-1001"
  >
    <div class="flex flex-row justify-between">
      <template v-if="type === 'hc'">
        <div>
          <h4 class="text-white text-base font-900 uppercase relative">
            {{ item.instrument_name }}
          </h4>
          <p class="font-500 text-xs leading-3.75 text-neutral-500 mt-2.5">
            {{ item.base_currency }}&nbsp;Settlement
          </p>
        </div>
        <SmallQuarter class="flex-none" :type="type" :grayscale="grayscale" />
      </template>
      <template v-else>
        <h4 class="text-white text-base font-900 uppercase">{{ title }}</h4>
        <SmallQuarter class="flex-none" :type="type" :grayscale="grayscale" />
      </template>
    </div>
    <template v-if="type !== 'hc'">
      <div class="mt-5.5">
        <h5 class="text-white text-2.66xl leading-9 font-400">
          {{ USD(item.strike_price).format() }}
        </h5>

        <p class="mt-2.5 text-white text-xs">
          {{ formatDate(item.expiration_date, 'yyyy/MM/dd hh:mm') }}
        </p>
        <p
          class="mt-2 text-xs text-neutral-500 font-500 leading-3.75 uppercase"
        >
          <span>{{ BUY_OR_SELL[type] }}</span>
          <span>&nbsp;{{ item.base_currency }}</span>
          <template v-if="showStatus">
            <span>&nbsp;-&nbsp;</span>
            <span v-if="isOrder">
              {{ HISTORY_ORDER_STATUS[item.order_status] }}
            </span>
            <span v-else>
              {{ HISTORY_POSITION_STATUS[item.status] }}
            </span>
          </template>
        </p>
      </div>
      <div class="mt-10">
        <p
          class="inline-block text-xs px-2 py-0.75 bg-white bg-opacity-3 rounded-0.75"
        >
          <span class="text-neutral-410 font-500">{{ p0 }}:</span>
          <span class="text-white font-600 ml-5px">
            {{ funds }}&nbsp;{{ item.quote_currency }}
          </span>
        </p>
        <br />
        <p
          class="mt-2 inline-block text-xs px-2 py-0.75 bg-white bg-opacity-3 rounded-0.75"
        >
          <span class="text-neutral-410 font-500">{{ totalLabel }}:</span>
          <span class="text-white font-600 ml-5px">
            {{ total }}&nbsp;{{ item.base_currency }}
          </span>
        </p>
      </div>
    </template>
  </div>
</template>
