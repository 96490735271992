import type { Maps, User } from '@/env'
import mixin from '@/mixin'
import fennec from '@/fennec'
import { connectMvm } from './connection/mvm'

export const API_HOST = import.meta.env.VITE_API_HOST
export const MIXIN_HOST = 'https://api.mixin.one'
export const MIXIN_CLIENT_ID = import.meta.env.VITE_MIXIN_CLIENT_ID

export const MENUS = [
  {
    enable: true,
    label: 'Mixin',
    class: 'xin',
    icon: 'coins-XIN',
    link: 'https://developers.mixin.one/docs/introduction',
    children: [
      {
        label: 'Mixin Messenger',
        class: 'mixin',
        icon: 'coins-XIN',
        id: (user: User) => user.mixin_id,
        address: (user: User) => user.mixin_uid,
        connect: (callback: any) =>
          mixin(
            {
              scope: 'PROFILE:READ ASSETS:READ',
              clientId: MIXIN_CLIENT_ID,
            },
            false,
            callback
          ),
        disconnect: (client: any) => client?.disconnect()
      },
      {
        label: 'Fennec',
        class: 'fennec',
        icon: 'connects-fennec',
        id: (user: User) => user.nickname,
        address: (user: User) => user.user_id,
        connect: (callback: any) => fennec(callback),
        disconnect: (client: any) => { },
      },
    ],
  },
  {
    enable: false,
    label: 'Starknet',
    icon: 'coins-SOL',
    class: 'sol',
    link: 'https://docs.solana.com/wallet-guide',
    children: [
      {
        label: 'Phantom',
        class: 'phantom',
        icon: 'connects-phantom',
      },
      {
        label: 'Solflare',
        class: 'solflare',
        icon: 'connects-solflare',
      },
    ],
  },
  {
    enable: false,
    label: 'Ethereum',
    icon: 'coins-ETH',
    class: 'eth',
    link: 'https://ethereum.org/en/wallets/',
    children: [
      {
        label: 'MetaMask',
        class: 'fennec',
        icon: 'connects-metamask',
        id: (user: User) => user.user_id,
        address: (user: User) => user.user_id,
        connect: (callback: any) => connectMvm(callback),
        disconnect: (client: any) => { },
      },
    ],
  },
]

export const ACTIONS: Maps<Maps<string | boolean>> = {
  // Dip Hunter: sell_put - Dip Hunter - 优买
  // ASK - PUT
  dh: {
    title: 'Dip Hunter',
    className: 'quarter-dh',
    p0: 'Premium (Interest)',
  },
  // Crash Protection: buy_put - Stable - 稳赢
  // BID - PUT
  cp: {
    title: 'Crash Protection',
    className: 'quarter-cp',
    p0: 'Insurance Fee',
  },
  // Bullish Protection: sell_call - Bearish - 大跌保 - 保卖 - ��
  // ASK - CALL
  bp: {
    title: 'Bullish Protection',
    className: 'quarter-bp',
    p0: 'Insurance Fee',
  },
  // Stable Profit: buy_call - Bullish - 大涨保 - 保买 - ��
  // BID - CALL
  sp: {
    title: 'Stable Profit',
    className: 'quarter-sp',
    p0: 'Premium (Interest)',
  },
  // History Call
  hc: {
    title: 'History - Call',
    className: 'quarter-hc',
    editable: true,
  },
}

// history order status
// Open:10 Canceling:35 Cancelled:40 Filled:50
export const HISTORY_ORDER_STATUS: Maps<string> = {
  10: 'Open',
  35: 'Canceling',
  40: 'Cancelled',
  50: 'Filled',
}

// history position status
export const HISTORY_POSITION_STATUS: Maps<string> = {
  10: 'Not Exercised',
  20: 'To be settled',
  30: 'Exercised',
}

// open order
export const OPEN_ORDER_STATUS: Maps<string> = {
  10: 'Open',
  20: 'Total',
  30: 'Total',
  40: 'Cancelled',
  50: 'Matching finished',
}

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const BUY_OR_SELL: Maps<string> = {
  dh: 'buy',
  sp: 'sell',
  cp: 'sell',
  bp: 'buy',
}

export const MARKETS = ['BTC', 'ETH']
export const MINS: Maps<number> = {
  'BTC': 0.1,
  'ETH': 1,
}