import type { MainStore, User, Search, SearchOptions } from '@/env'
import { defineStore } from 'pinia'
import api from '@/api'

export const useStore = defineStore('main', {
  state: () => {
    return {
      token: '',
      type: '',
      user: null,
      network: -1,
      wallet: -1,
      reconnect: false,
    } as MainStore
  },
  getters: {
    logged(state): boolean {
      return Boolean(state.token.length)
    },
  },
  actions: {
    setStuff(network: number, wallet: number) {
      this.network = network
      this.wallet = wallet
    },
    setType(type: string) {
      this.type = type
    },
    setToken(token: string) {
      this.token = token
    },
    setUser(user: User) {
      this.user = {
        ...this.user,
        ...user,
      }
    },

    logout() {
      this.reset()
      window.location.href = '/'
    },

    reset() {
      this.token = ''
      this.type = ''
      this.network = -1
      this.wallet = -1
      this.user = null
      this.reconnect = false
    },

    get(path: string, init?: RequestInit, v = 1) {
      if (this.logged) {
        if (!init) {
          init = {}
        }
        init.headers = {
          ...init.headers,
          Authorization: `Bearer ${this.token}`,
        }
      }
      return api.get(path, init, v).catch((err) => {
        if (err && err.code === 40001) {
          // this.logout()
          this.reconnect = true
        }

        throw err
      })
    },

    post(path: string, init?: RequestInit) {
      if (this.logged) {
        if (!init) {
          init = {}
        }
        init.headers = {
          ...init.headers,
          Authorization: `Bearer ${this.token}`,
        }
      }
      return api.post(path, init).catch((err) => {
        if (err && err.code === 40001) {
          // this.logout()
          this.reconnect = true
        }

        throw err
      })
    },

    async fetchUser() {
      try {
        const user = (await this.post('user/me')) as User
        this.setUser(user)
      } catch (err) {
        console.error(err)
      }
    },
  },
  persist: true,
})

export const useSearchStore = defineStore('search', {
  state: () => {
    return {
      type: '',
      side: '',
      optionType: '',
      deliveryType: '',
      quoteCurrency: '',
      baseCurrency: 'BTC',
    } as Search
  },
  actions: {
    object() {
      return {
        side: this.side,
        optionType: this.optionType,
        deliveryType: this.deliveryType,
        quoteCurrency: this.quoteCurrency,
        baseCurrency: this.baseCurrency,
      }
    },
    setMarket(market: string) {
      const options: SearchOptions = {}
      options.baseCurrency = market
      switch (this.type) {
        case 'dh':
        case 'cp':
          options.quoteCurrency = 'USDC'
          break
        case 'sp':
        case 'bp':
          options.quoteCurrency = options.baseCurrency
          break
      }
      Object.assign(this, options)
    },
    dh() {
      Object.assign(this, {
        type: 'dh',
        side: 'BID',
        optionType: 'PUT',
        deliveryType: 'CASH',
        quoteCurrency: 'USDC',
      })
    },
    sp() {
      Object.assign(this, {
        type: 'sp',
        side: 'BID',
        optionType: 'CALL',
        deliveryType: 'PHYSICAL',
        quoteCurrency: this.baseCurrency,
      })
    },
    cp() {
      Object.assign(this, {
        type: 'cp',
        side: 'ASK',
        optionType: 'PUT',
        deliveryType: 'CASH',
        quoteCurrency: 'USDC',
      })
    },
    bp() {
      Object.assign(this, {
        type: 'bp',
        side: 'ASK',
        optionType: 'CALL',
        deliveryType: 'PHYSICAL',
        quoteCurrency: this.baseCurrency,
      })
    },
    intoSearchParams(search?: SearchOptions) {
      return new URLSearchParams({ ...this.object(), ...search })
    },
  },
  persist: true,
})