<template>
  <div
    id="networks"
    class="px-5 pt-10 pb-6 box-border w-103 bg-neutral-1001 rounded-2.5xl absolute inset-border select-none modal-container"
  >
    <h3 class="font-800 text-white text-2xl mb-11.25 mx-2">Select a network</h3>
    <SvgIcon
      name="close"
      class="absolute top-10 right-7 cursor-pointer text-neutral-455 btn-close"
      un-hover="text-white"
      width="18"
      height="18"
      @click="$emit('close')"
    />
    <a
      class="flex items-center justify-between h-18 rounded-3 box-border bg-dark-401 mt-4.5 px-6 font-500 text-white text-lg"
      v-for="(item, i) in MENUS"
      :key="i"
      :class="{ selected: index === i, [`${item.class}`]: 1 }"
      @click="select(i, item)"
    >
      <SvgIcon :name="item.icon" width="40" height="40" class="mr-4" />
      <span class="flex-1 text-left">{{ item.label }}</span>
      <SvgIcon class="icon-arrow" name="arrow" width="7" height="12" />
    </a>
  </div>
</template>

<style>
#networks a {
  cursor: pointer;
  border: 1.3px solid transparent;
  transition: border-color 0.2s ease;
}
#networks a.eth:hover,
#networks a.eth.selected {
  border-color: #a8b9fe;
}
#networks a.sol:hover,
#networks a.sol.selected {
  border-color: #5df8ca;
}
#networks a.xin:hover,
#networks a.xin.selected {
  border-color: #1eb5fa;
}
#networks a .icon-arrow {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease;
}
#networks a:hover .icon-arrow {
  opacity: 1;
  visibility: visible;
}
</style>

<script setup lang="ts">
import { ref } from 'vue'
import { useToast, POSITION } from 'vue-toastification'
import ErrorIcon from '@/components/toasts/ErrorIcon.vue'
import ErrorToast from '@/components/toasts/ErrorToast.vue'
import CloseIcon from '@/components/toasts/CloseIcon.vue'
import { MENUS } from '@/constants'

const emit = defineEmits(['close', 'next', 'select-network'])

const toast = useToast()

const index = ref(-1)

const select = (n: number, item: Record<string, any>) => {
  if (!item || !item.enable) {
    toast.error(
      {
        type: 'error',
        component: ErrorToast,
        props: {
          text: 'This feature is under development',
        },
      },
      {
        // timeout: 10000,
        timeout: false,
        icon: ErrorIcon,
        closeButton: CloseIcon,
        position: POSITION.TOP_CENTER,
        hideProgressBar: true,
      }
    )
    return
  }
  index.value = n
  emit('select-network', n)
  emit('next')
}
</script>
