<template>
  <transition name="modal">
    <div
      v-if="show"
      class="fixed w-screen h-screen top-0 bg-neutral-1000 bg-opacity-40 z-1024 flex items-center justify-center modal"
    >
      <component
        v-if="dialog === Networks"
        :is="dialog"
        @select-network="selectNetwork"
        @close="close"
        @next="next"
      />
      <component
        v-else-if="dialog === Wallets"
        :is="dialog"
        :network="network"
        :wallet="wallet"
        :callback="callback"
        :connect="connect"
        @select-wallet="selectWallet"
        @close="close"
        @next="next"
        @prev="prev"
      />
      <component
        v-else-if="dialog === ScanToConnect"
        :is="dialog"
        :network="network"
        :wallet="wallet"
        :code="codeId"
        @select-wallet="selectWallet"
        @close="close"
        @next="next"
        @prev="prev"
      />
      <component
        v-else
        :is="dialog"
        :network="network"
        :wallet="wallet"
        @close="$emit('close')"
        @disconnect="disconnect"
      />
    </div>
  </transition>
</template>

<script setup lang="ts">
import { ref, computed, onUpdated } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from '@/stores'
import api from '@/api'

import Networks from './Networks.vue'
import Wallets from './Wallets.vue'
import Account from './Account.vue'
import ScanToConnect from './ScanToConnect.vue'

interface Props {
  show: boolean
}

const emit = defineEmits(['close'])
const DIALOGS = [Networks, Wallets, ScanToConnect, Account]

const props = defineProps<Props>()
const route = useRoute()
const router = useRouter()
const store = useStore()

const index = ref(0)
const network = ref(-1)
const wallet = ref(-1)
const client = ref(null)
const codeId = ref('')

const dialog = computed(() => DIALOGS[index.value])

const next = () => {
  index.value++
}
const prev = (disconnect: boolean) => {
  if (disconnect) {
    wallet.value = -1
    disconnectClient()
  }
  index.value--
}
const selectNetwork = (n: number) => {
  network.value = n
}
const selectWallet = (n: number) => {
  wallet.value = n
}
const close = (back?: boolean) => {
  if (back) {
    prev(true)
  }
  emit('close')
}

const callback = async (
  type: string,
  code: string,
  step: number,
  account: any
) => {
  let token = ''
  switch (type) {
    case 'fennec':
      if (code && step === 3) {
        token = code
      }
      break
    case 'mixin':
      if (code) {
        if (step === 0) {
          codeId.value = code
          index.value = 2
        } else if (step === 1 && index.value === 2) {
          try {
            const { access_token } = await api.getOAuthToken(code)
            const res = await api.getToken(access_token)
            token = res.token
          } catch (err) {
            console.log(err)
          }
        }
      }
      break
    case 'mvm':
      // mvm auth access token
      if (step === 1) {
        token = code
      }
      break
    default:
  }

  if (!token) {
    return
  }

  try {
    store.setType(type as string)
    store.setToken(token)
    await store.fetchUser()
    if (account) {
      // @ts-ignore
      store.setUser({
        type,
        nickname: account.full_name,
        user_id: account.user_id,
      })
    }
    store.setStuff(network.value, wallet.value)
    index.value = 3
    router.replace({
      path: route.path,
      query: {
        t: Date.now(),
      },
    })
  } catch (err) {}
}

const connect = (c: any) => (client.value = c)

const disconnect = () => {
  store.logout()
  network.value = -1
  wallet.value = -1
  index.value = 0
  disconnectClient()
  emit('close')
}

const disconnectClient = () => {
  codeId.value = ''
  if (client.value) {
    // @ts-ignore
    client.value?.disconnect()
    client.value = null
  }
}

onUpdated(() => {
  if (props.show) {
    if (store.logged && store.network !== -1 && store.wallet !== -1) {
      network.value = store.network
      wallet.value = store.wallet
      index.value = 3
    }
  } else {
    disconnectClient()
  }
})
</script>
