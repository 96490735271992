<script setup lang="ts">
import type { Order, Pagination } from '@/env'
import { onMounted, onUnmounted, ref } from 'vue'
import { onBeforeRouteUpdate, useRoute } from 'vue-router'
import { useStore } from '@/stores'
import { getType } from '@/utils'

const route = useRoute()
const store = useStore()

const state = ref(-1) // -1: init, 0: empty, 1: loading, 2: has data
const current = ref(1)
const pages = ref(0)
const total = ref(0)
const records = ref<Order[]>([])
const ignored = new Set([0, 1, 3])

const fetch = async (page = 1, limit = 100) => {
  if (!store.logged) return
  state.value = 1
  try {
    const res = (await store.get(
      `orders?status=closed&current=${page}&size=${limit}`
    )) as Pagination<Order>
    pages.value = res.pages
    total.value = res.total
    if (page === 1) {
      records.value = res.records
    } else {
      records.value.push(...res.records)
    }
    current.value++
    if (pages.value === 0) {
      state.value = 0
    } else if (pages.value <= current.value) {
      state.value = 3
    } else {
      state.value = 2
    }
  } catch (err) {}
}

const onRefresh = () => {
  current.value = 1
  records.value = []
  fetch(current.value)
}

const onScroll = ({ target }: any) => {
  if (ignored.has(state.value)) {
    return
  }

  const { clientHeight, scrollHeight, scrollTop } = target
  if (clientHeight + scrollTop >= scrollHeight - 10) {
    fetch(current.value)
  }
}

onBeforeRouteUpdate(() => {
  onRefresh()
})

onMounted(() => {
  onRefresh()
  const dom = document.getElementById('scroll-container')
  dom?.addEventListener('scroll', onScroll, false)
  dom?.scrollTo({ top: Number(route.meta.scrollTop) })
})

onUnmounted(() => {
  document
    .getElementById('scroll-container')
    ?.removeEventListener('scroll', onScroll)
})
</script>

<template>
  <div class="w-full pt-3 pb-5">
    <div class="flows justify-between">
      <router-link
        v-for="item in records"
        :key="item.order_id"
        :to="{
          name: 'Order',
          params: {
            id: item.order_id,
          },
        }"
      >
        <card
          :item="item"
          :is-order="true"
          :show-status="true"
          :type="getType(item.side, item.option_type)"
        />
      </router-link>
    </div>

    <!-- 148 - (6 + 24 + 56) = 62 -->
    <div v-if="state < 2" class="flex flex-col items-center mt-14">
      <p v-if="state === 0" class="text-sm font-400 text-neutral-310 mt-15.5">
        You have no open orders
      </p>
      <loading v-if="state === 1" />
    </div>
  </div>
</template>
