<script setup lang="ts">
import type { Amount } from '@/env'
import { computed, ref, onBeforeMount } from 'vue'
import { nanoid } from 'nanoid'
import { useRoute } from 'vue-router'
import { useSearchStore } from '@/stores'
import { leftSettlementPeriod, USD } from '@/utils'
import api from '@/api'

import OrderDetailsCard from '@/components/OrderDetailsCard.vue'

const route = useRoute()
const search = useSearchStore()
const step = ref(0)
const show = ref(true)
const showBack = ref(false)
const showOrder = ref(false)
const disabled = ref(false)
const price = ref(0)
const date = ref(0)
const pricesLoading = ref(false)
const prices = ref<Amount[]>([])
const datesLoading = ref(false)
const dates = ref<Amount[]>([])
const index = ref(0)
const amount = ref('')

const value = computed(() => Math.floor((step.value + 1) * (1 / 3) * 100))

const next = () => {
  if (step.value === 0) {
    step.value++
    disabled.value = true
    showBack.value = true
  } else if (step.value === 1) {
    step.value++
    show.value = false
    showOrder.value = true
  }
}

const prev = () => {
  if (step.value === 1) {
    step.value--
    disabled.value = true
    showBack.value = false
  } else if (step.value === 2) {
    closeOrder()
  }
}

const closeOrder = () => {
  if (step.value === 2) {
    step.value--
  }
  show.value = true
  showOrder.value = false
}

const fetch = async (flag: boolean) => {
  pricesLoading.value = true
  try {
    const res = await api.getStrikePrices(search.intoSearchParams())
    if (Array.isArray(res) && res.length) {
      if (flag) {
        price.value = res[0]
      }
      prices.value = res.map((value) => ({
        id: nanoid(),
        value,
      }))
      dates.value = res.map((value) => ({
        id: value,
        value: [],
      }))
    } else {
      prices.value = []
      dates.value = []
      index.value = 0
      price.value = 0
      date.value = 0
    }
  } catch (err) {}
  pricesLoading.value = false
}

const fetchDates = async (price: number) => {
  datesLoading.value = true
  try {
    const res = await api.getExpiryDatesByPrice(
      price,
      search.intoSearchParams()
    )
    if (Array.isArray(res) && res.length) {
      index.value = 0
      date.value = new Date(res[0].expiration_date).getTime()
      const i = dates.value.findIndex((d) => d.id === price)
      const box = {
        id: price,
        value: res.map((value) => ({
          id: nanoid(),
          value,
        })),
      }
      if (i === -1) {
        dates.value.push(box)
      } else {
        dates.value.splice(i, 1, box)
      }
    } else {
      dates.value = []
      date.value = 0
      index.value = 0
    }
  } catch (err) {}
  datesLoading.value = false
}

const currentDates = computed(() => {
  if (price.value === 0) return []
  const item = dates.value.find((d) => d.id === price.value)
  return item ? item.value : []
})

// 优买
// BTC
// /market/strike-prices?side=BID&optionType=PUT&deliveryType=CASH&quoteCurrency=USDC&baseCurrency=BTC
// /market/price/40000?side=BID&optionType=PUT&deliveryType=CASH&quoteCurrency=USDC&baseCurrency=BTC
// ETH
// /market/strike-prices?side=BID&optionType=PUT&deliveryType=CASH&quoteCurrency=USDC&baseCurrency=ETH
// /market/price/3000?side=BID&optionType=PUT&deliveryType=CASH&quoteCurrency=USDC&baseCurrency=ETH
onBeforeMount(() => search.dh())
</script>

<template>
  <div
    class="relative h-screen w-screen flex justify-center action-screen scroll-container overflow-y-auto"
  >
    <div
      v-show="show"
      class="absolute top-33 flex flex-col items-center justify-start mx-auto"
    >
      <h3 class="text-2.5xl font-900 text-white uppercase tracking-0.2em">
        Dip Hunter
      </h3>
      <p class="text-lg0.8214 font-400 text-white text-opacity-30 mt-4">
        Price up earns dollar, price down earns coin
      </p>

      <router-view v-slot="{ Component, route }">
        <transition :name="route.meta.transition || 'fade'" mode="out-in">
          <component
            :is="Component"
            v-model:price="price"
            :prices="prices"
            :pricesLoading="pricesLoading"
            v-model:date="date"
            :dates="dates"
            :currentDates="currentDates"
            :datesLoading="datesLoading"
            v-model:disabled="disabled"
            :showBack="showBack"
            :value="value"
            v-model:index="index"
            v-model:amount="amount"
            v-model:show="show"
            v-model:showOrder="showOrder"
            @prev="prev"
            @next="next"
            :fetch="fetch"
            :fetchDates="fetchDates"
          />
        </transition>
      </router-view>
    </div>

    <transition name="slide-fade-in">
      <order-details-card
        v-if="showOrder"
        type="dh"
        label="Buy"
        v-model:price="price"
        :prices="prices"
        :getPrice="(value) => value"
        :formatPrice="(value) => USD(value).format()"
        v-model:date="date"
        :dates="currentDates"
        :formatDate="(value) => leftSettlementPeriod(value.expiration_date)"
        :getDate="(value) => new Date(value.expiration_date).getTime()"
        @close="closeOrder"
        :index="index"
        v-model:amount="amount"
        class="top-30 action-order-details"
      />
    </transition>

    <transition name="fade">
      <router-link
        v-if="step === 0 && !showOrder"
        :to="`/dip-hunter${route.name === 'DipHunterNormal' ? '/list' : ''}`"
        class="fixed bottom-9.5 left-11 z-1024 text-0.9sm text-neutral-310 flex items-center font-700 cursor-pointer transition-color"
        un-hover="text-white"
        :class="{
          'pointer-events-none': showOrder,
        }"
      >
        <SvgIcon name="switch" width="14" height="14" class="mr-3" />
        <template v-if="route.name === 'DipHunterNormal'">Pro</template>
        <template v-else>Lite</template>
      </router-link>
    </transition>
  </div>
</template>

<style scoped>
.action-screen::before {
  background: linear-gradient(180deg, #ff6f61 0%, rgba(255, 111, 97, 0) 100%);
}
</style>
