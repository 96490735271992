<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useToast, POSITION } from 'vue-toastification'
import { useStore, useSearchStore } from '@/stores'

import Menu from '@/components/Menu.vue'
import Markets from '@/components/Markets.vue'
import UnderConstruction from '@/components/UnderConstruction.vue'
import AuthModal from '@/components/modals/auth/index.vue'
import ErrorIcon from '@/components/toasts/ErrorIcon.vue'
import ErrorToast from '@/components/toasts/ErrorToast.vue'
import CloseIcon from '@/components/toasts/CloseIcon.vue'

const route = useRoute()
const store = useStore()
const search = useSearchStore()
const toast = useToast()

const wrapper = ref()
const showUd = ref(false)
const show = ref(false)

const open = () => {
  show.value = true
}

const mvmAddressAbbr = computed(() => {
  let addr = store.user?.user_id
  if (addr) {
    return `${addr.slice(0, 6)}...${addr.slice(addr.length - 4, addr.length)}`
  }
  return addr
})

watch(
  () => store.logged && store.reconnect,
  (a) => {
    if (a) {
      if (route.path === '/') {
        store.logout()
        return
      }

      toast.error(
        {
          type: 'error',
          component: ErrorToast,
          props: {
            text: 'Please connect your wallet',
          },
        },
        {
          timeout: 10000,
          icon: ErrorIcon,
          closeButton: CloseIcon,
          position: POSITION.TOP_CENTER,
          hideProgressBar: true,
        }
      )

      store.reset()
    }
  }
)

onMounted(async () => {
  if (store.logged) {
    await store.fetchUser()
  }
})
</script>

<template>
  <div ref="wrapper" id="wrapper">
    <header
      id="header"
      class="absolute z-50 w-full backdrop-blur-8 select-none"
    >
      <nav class="flex flex-row items-center px-8">
        <router-link
          class="text-neutral-310 cursor-pointer flex items-center h-20"
          un-hover="text-white"
          to="/"
        >
          <SvgIcon name="logo" width="167" height="29.2" />
        </router-link>
        <div class="ml-5.5 text-neutral-310 text-lg font-700">
          <router-link
            class="ml-15 cursor-pointer"
            un-hover="text-white"
            to="/"
          >
            Products
          </router-link>
          <router-link
            class="ml-15 cursor-pointer"
            un-hover="text-white"
            to="/positions"
          >
            Positions
          </router-link>
        </div>
        <div class="flex-1 flex items-center justify-end">
          <div
            id="markets"
            role="button"
            class="text-white bg-white bg-opacity-5 py-3 pl-5 pr-3.5 rounded-8 inline-flex items-center justify-center cursor-pointer relative mr-5"
          >
            <div class="flex flex-row items-center h-5">
              <SvgIcon
                :name="`coins-${search.baseCurrency}-24`"
                width="20"
                height="20"
                class="rounded-full"
              />
              <span class="font-700 text-sm text-white ml-1.5 mr-1 w-7.5">
                {{ search.baseCurrency }}
              </span>
              <SvgIcon name="arrow-down" width="20" height="20" />
            </div>
            <Markets
              class="absolute top-9 text-neutral-310 font-500 text-base w-80 menu invisible"
            />
          </div>
          <button
            role="button"
            id="connect-wallet"
            class="font-700 text-sm text-white bg-white bg-opacity-5 py-2.75 px-7 rounded-8 box-border border border-transparent inline-flex items-center cursor-pointer"
            un-hover="border-white"
            @click="open"
          >
            <template v-if="store.logged">
              <SvgIcon
                name="avatar"
                width="18"
                height="18"
                class="mr-2.5 text-neutral-1001"
              />
              <template v-if="store.user">
                <span v-if="store.type === 'fennec'">
                  {{ store.user.nickname }}
                </span>
                <span v-else-if="store.type === 'mvm'">
                  {{ mvmAddressAbbr }}
                </span>
                <span v-else>
                  {{ store.user.mixin_id }}
                </span>
              </template>
            </template>
            <template v-else>
              <SvgIcon name="wallet" width="18" height="18" class="mr-2.5" />
              Connect Wallet
            </template>
          </button>
          <span id="divide" class="inline-block mx-6 bg-neutral-500" />
          <div
            id="dots"
            role="button"
            class="text-white w-9.5 h-9.5 bg-white bg-opacity-5 rounded-full box-border border border-transparent inline-flex items-center justify-center cursor-pointer relative relative relative relative"
            un-hover="border-white"
          >
            <SvgIcon name="three-dots" width="24" height="24" color="white" />
            <Menu
              class="absolute right-0 top-9 text-neutral-310 font-500 text-base w-55 invisible"
            />
          </div>
        </div>
      </nav>
    </header>

    <main class="h-screen flex justify-center relative">
      <router-view v-slot="{ Component, route }">
        <transition :name="route.meta.transition || 'fade'" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>

      <transition name="fade" mode="in-out">
        <under-construction v-if="showUd" id="ud" class="absolute show z-100" />
      </transition>
    </main>
  </div>

  <teleport to="body">
    <auth-modal :show="show" @close="show = false" />
  </teleport>
</template>

<style>
body {
  font-family: mulish, inter, -apple-system, blinkmacsystemfont, 'segoe ui',
    roboto, oxygen, ubuntu, cantarell, 'fira sans', 'droid sans',
    'helvetica neue', sans-serif;
  min-height: 100vh;
  font-size: 1rem;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

#wrapper {
  transition: filter 0.2s ease-in-out;
}

/*
:hover {
  cursor: url('@/assets/cursor.svg'), 4 4, auto !important;
}
*/

#header a {
  transition: color 0.2s ease, border-color 0.2s ease;
}
.animated {
  transition: color 0.2s ease, border-color 0.2s ease,
    background-color 0.2s ease;
}

#divide {
  width: 1.5px;
  height: 12px;
}

#dots:hover #menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

#ud {
  opacity: 0;
  transform: translate3d(0, -80px, 0);
  transition: opacity 0.2s ease,
    transform 0.233s cubic-bezier(0.215, 0.61, 0.355, 1);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.07),
    0px 12px 60px rgba(25, 22, 21, 0.4), inset 0px 3px 46px rgba(0, 0, 0, 0.1);
}

#ud.show {
  opacity: 1;
  transform: translate3d(0, 116px, 0);
}

.inset-border::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -1px;
  border-radius: inherit;
  backdrop-filter: blur(40px);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.07),
    0px 12px 60px rgba(25, 22, 21, 0.4), inset 0px 3px 46px rgba(0, 0, 0, 0.1);
}

.btn-back,
.btn-close {
  transition: color 0.2s ease;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fadeInDown-enter-active,
.fadeInDown-leave-active {
  opacity: 1;
  height: 68px;
  transition: opacity 0.2s ease, height 0.2s ease;
}
.fadeInDown-enter-from,
.fadeInDown-leave-to {
  opacity: 0;
  height: 0;
}

.Vue-Toastification__container {
  z-index: 9999;
  position: fixed;
  padding: 4px;
  width: 600px;
  box-sizing: border-box;
  display: flex;
  min-height: 100%;
  color: #fff;
  flex-direction: column;
  pointer-events: none;
}

.modal {
  transition: opacity 0.3s ease;
}

.modal-container {
  transition: transform 0.3s ease;
}

.modal-container::before {
  transition: backdrop-filter 0.3s ease;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-enter-from .modal-container::before,
.modal-leave-to .modal-container::before {
  backdrop-filter: blur(40px) opacity(0);
}

.modal-enter-to .modal-container::before,
.modal-leave-from .modal-container::before {
  backdrop-filter: blur(40px) opacity(1);
}

.scroll-container {
  --color-scrollbar: rgba(255, 255, 255, 0);
  --color-scrollbar-thumb: rgba(255, 255, 255, 0.2);
  scrollbar-width: thin;
  scrollbar-color: var(--color-scrollbar-thumb) var(--color-scrollbar);
}
.scroll-container::-webkit-scrollbar {
  width: 6px !important;
  background: var(--color-scrollbar);
  border-radius: 20px;
}
.scroll-container::-webkit-scrollbar-thumb {
  width: 6px !important;
  background: #0e0e0e;
  border: 1px solid var(--color-scrollbar-thumb);
  box-sizing: border-box;
  border-radius: 20px;
}
.scroll-container::-webkit-scrollbar-track {
  border-radius: 20px;
}

.action-screen::before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 28.61%;
  left: 0;
  z-index: -1;
  opacity: 0.15;
}

.action-screen .btn {
  transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1),
    opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.action-card {
  will-change: transform, opacity;
  transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1),
    opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  appearance: none;
}

.action-card.slide-fade-out-enter-from,
.action-card.slide-fade-out-leave-to {
  transform: translate3d(0, -36px, 0);
  opacity: 0;
}

.action-card.slide-fade-in-enter-from,
.action-card.slide-fade-in-leave-to {
  transform: translate3d(0, 20px, 0);
  opacity: 0;
}

.action-order-details {
  transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.action-order-details.slide-fade-in-enter-from,
.action-order-details.slide-fade-in-leave-to {
  transform: translate3d(0, 14px, 0);
  opacity: 0;
}

.action-screen .progress {
  --value: 0;
  position: relative;
  background: #4a4d54;
}

.action-screen .progress::after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: var(--value);
  border-radius: 12px;
  background: white;
  transition: width 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
}
</style>
