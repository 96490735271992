<script setup lang="ts">
import type { Position, Instrument } from '@/env'
import { ref, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/stores'
import { getType, formatDate, USD, parseInstrumentName } from '@/utils'
import { ACTIONS, HISTORY_POSITION_STATUS } from '@/constants'

const route = useRoute()
const router = useRouter()
const store = useStore()

// -1: init, 0: empty, 1: loading, 2: has data
const state = ref(-1)
// @ts-ignore
const item = ref<Position>({})

const fetch = async (id: string) => {
  state.value = 1
  const res = (await store.get(`position/${id}`, undefined, 2)) as Position
  if (res && res.position_id) {
    state.value = 2
    res.instrument = parseInstrumentName(res.instrument_name) as Instrument
    if (Array.isArray(res.trade_list)) {
      const optionType = res.instrument?.optionType
      if (optionType) {
        res.trade_list.forEach((t) => {
          const action = ACTIONS[getType(t.side, optionType as string)]
          t.title = action.title
          t.funds =
            (t.side === 'ASK' ? '+' : '-') +
            parseFloat((Number(t.amount) * Number(t.price)).toPrecision(12))
        })
      }
    }
    item.value = res
  } else {
    state.value = 0
    // @ts-ignore
    item.value = {}
  }
}

const type = computed(() => {
  const { side, type } = item.value
  return getType(side, type)
})

const isHC = computed(() => type.value === 'hc')

const status = computed(() => HISTORY_POSITION_STATUS[item.value.status])

const expired = computed(() => {
  const now = new Date()
  const date = new Date(item.value.expiration_date)
  return now > date ? 'Expired' : 'Due'
})

const received = computed(() => {
  const { settlement_info, side } = item.value

  if (settlement_info) {
    return side === 'ASK'
      ? settlement_info?.refund_margin
      : settlement_info?.bid_earnings
  }

  return 0
})

const deliveryPrice = computed<number>(() => {
  return item.value.settlement_info?.underlying_price || 0
})

onMounted(() => {
  if (store.logged) {
    fetch(route.params.id as string)
  }
})
</script>

<template>
  <div class="w-198 mx-auto pt-20">
    <SvgIcon
      name="back"
      width="40"
      height="40"
      class="text-neutral-310 cursor-pointer ml--16 mt-11.5 animated"
      un-hover="text-white"
      @click="router.back"
    />
    <h3
      class="text-white font-800 text-4xl leading-11.25 pb-6 border-b border-neutral-800 mt-6"
    >
      <span class="capitalize">History</span>
    </h3>

    <div v-if="state < 2" class="flex flex-col items-center mt-14">
      <p v-if="state === 0" class="text-sm font-400 text-neutral-310 mt-15.5">
        You have no this position
      </p>
      <Loading v-if="state === 1" />
    </div>
    <div
      v-else
      class="flex flex-row flex-wrap justify-between mt-6 pb-42.5 space-x-8"
    >
      <div
        class="space-y-4.5"
        :class="{
          'w-104': !isHC,
          'w-full': isHC,
        }"
      >
        <Card
          class="w-full"
          :item="item"
          :is-order="false"
          :type="type"
          :class="{
            '!h-60': isHC,
          }"
          un-hover="!bg--neutral-1000 cursor-default"
        />

        <div
          class="flex flex-row bg--neutral-1000 px-7 pt-8 pb-8.5 rounded-5 border border-white border-opacity-20 box-border table w-full font-400"
        >
          <div class="table-header-group">
            <div
              class="table-row text-neutral-410 text-0.9375base leading-4.75"
            >
              <div class="table-cell">Position History</div>
              <div class="table-cell">Size</div>
              <div class="table-cell text-right">Premium</div>
            </div>
          </div>
          <div
            class="table-row-group text-white text-base leading-5 space-y-4.5"
          >
            <div
              v-for="trade in item.trade_list"
              :key="trade.trade_id"
              class="table-row"
            >
              <div class="table-cell pt-5">
                <div>{{ formatDate(trade.created_at, 'yyyy/MM/dd') }}</div>
                <div class="text-xss leading-3.75 text-neutral-310">
                  {{ trade.title }}
                </div>
              </div>
              <div class="table-cell">{{ trade.amount }}</div>
              <div class="table-cell text-right">{{ trade.funds }}</div>
            </div>
          </div>
        </div>

        <div
          v-if="!isHC && item.status === 30"
          class="flex flex-row bg--neutral-1000 px-7 pt-8 pb-8.5 rounded-5 border border-white border-opacity-20 box-border table w-full font-400"
        >
          <div class="table-header-group">
            <div class="table-row font-700 text-white text-4.75 leading-6">
              <div class="table-cell">Settlement information</div>
            </div>
          </div>
          <div
            class="table-row-group text-neutral-410 text-base leading-5 space-y-4.5"
          >
            <div class="table-row">
              <div class="table-cell pt-5">
                <div>Delivery Price</div>
              </div>
              <div class="table-cell text-right text-white">
                {{ USD(deliveryPrice).format() }}
              </div>
            </div>
            <div class="table-row">
              <div class="table-cell pt-5">
                <div>Receive</div>
              </div>
              <div class="table-cell text-right text-white">
                {{ received }} {{ item.quote_currency }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-86" v-if="!isHC">
        <ul
          class="min-w-82 mx-auto text-neutral-200 font-400 text-sm0.9 divide-y divide-opacity-43 divide-neutral-500 mt-2 mb-9.5"
        >
          <li class="flex justify-between px-1 py-4.5">
            <label>Contract Status</label>
            <span>{{ expired }}</span>
          </li>
          <li class="flex justify-between px-1 py-4.5">
            <label>Exercise status</label>
            <span>{{ status }}</span>
          </li>
          <li
            class="flex justify-between px-1 py-4.5"
            v-if="type === 'dh' || type === 'sp'"
          >
            <label>Margin</label>
            <span>{{ item.margin }} {{ item.quote_currency }}</span>
          </li>
          <!-- <li class="flex justify-between px-1 py-4.5">
            <label>Price</label>
            <span>
              {{ formater.format(item.exercised_size * item.strike_price) }} /
              {{ item.base_currency }}
            </span>
          </li> -->
          <li class="flex justify-between px-1 py-4.5">
            <label>Settlement</label>
            <span>{{ item.quote_currency }}</span>
          </li>
          <li class="flex justify-between px-1 py-4.5">
            <label>Asset ID</label>
            <span>{{ item.position_num }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
